.root {
	composes: g-grid-container from global;
	composes: g-grid from global;
	row-gap: var(--hdsplus-spacing-08);

	@media (--medium-up) {
		row-gap: var(--hdsplus-spacing-12);
	}
}

.breadcrumbs {
	grid-column: 1 / -1;
	font-weight: 500;
}

.text {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-10);

	@media (--medium-up) {
		grid-column: 1 / 8;
	}
}

.header {
	display: grid;
	gap: var(--hdsplus-spacing-05);
}

.headerInner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-05);
}

.eyebrow {
	font-weight: 600;
}

.headline {
	font-weight: 600;
	font-family: var(--hdsplus-typography-display-expressive-300-font-family);
	font-size: var(--hdsplus-typography-display-expressive-300-font-size);
	line-height: var(--hdsplus-typography-display-expressive-300-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-300-letter-spacing
	);

	@media (--medium-up) {
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-400-letter-spacing
		);
	}

	@media (--large) {
		font-size: var(--hdsplus-typography-display-expressive-600-font-size);
		line-height: var(--hdsplus-typography-display-expressive-600-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-600-letter-spacing
		);
	}
}

.date {
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);
	letter-spacing: var(
		--hdsplus-typography-body-200-letter-spacing
	);

	@media (--large) {
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-body-300-letter-spacing
		);
	}
}


.subheadline {
	font-weight: 600;
	font-family: var(--hdsplus-typography-display-expressive-200-font-family);
	font-size: var(--hdsplus-typography-display-expressive-200-font-size);
	line-height: var(--hdsplus-typography-display-expressive-200-line-height);
	letter-spacing: var(
		--hdsplus-typography-display-expressive-200-letter-spacing
	);

	@media (--large) {
		font-size: var(--hdsplus-typography-display-expressive-300-font-size);
		line-height: var(--hdsplus-typography-display-expressive-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-300-letter-spacing
		);
	}
}

.description {
	font-family: var(--hdsplus-typography-body-200-font-family);
	font-size: var(--hdsplus-typography-body-200-font-size);
	line-height: var(--hdsplus-typography-body-200-line-height);
	letter-spacing: var(
		--hdsplus-typography-body-200-letter-spacing
	);

	@media (--large) {
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
		letter-spacing: var(
			--hdsplus-typography-body-300-letter-spacing
		);
	}
	
	& > *:first-child {
		margin-top: 0;
	}
	
	& p {
		margin: var(--hdsplus-spacing-04) 0 0;
	}
}

.headshotsWrapper {
	grid-column: 1 / -1;
	margin-top: var(--hdsplus-spacing-10);

	@media (--medium-up) {
		margin-top: 0;
		grid-column: 9 / -1;
	}
}

.headshots {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: var(--hdsplus-spacing-05);
	row-gap: var(--hdsplus-spacing-07);
}

.details {
	grid-column: 1 / -1;
	display: grid;
	gap: var(--hdsplus-spacing-05);
}

.headshot {
	grid-column: auto / span 1;
}

.badges {
	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-03);
}
